.formWrapper {
  background-color: white;

  @media all and (max-width: 767px) {
    margin: 0 -0.75rem;
  }
}

.changeBtn {
  background: none;
  border: 0;
  font-size: 1rem;
}
